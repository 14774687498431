import React, { Fragment } from "react"

import { Link } from "gatsby"

import "./css/Benefits.min.css"

const Title = () => (
  <div className="benefits-title-link-area">
    <h1>TECHNOLOGY - <br />THE GREAT ENABLER</h1>
  </div>
)

const SubTitle = () => <h3>By leveraging emergent technologies, we develop solutions</h3>

const Description = () => (
  <div className="benefits-point-explain">
    <p>
    CommonTown develops educational solutions that leverage deep technology, in-depth research, and a thorough understanding of our users. We collaborate closely with early adopters to ensure that our offerings are intuitive, easy to use, and highly effective. As a spin-off from A*STAR, founded in 1999, CommonTown  is at the forefront of technological innovation in the education industry, and we take pride in our ability to continually push boundaries and exceed expectations using deep tech and adaptive learning in our applications.
    </p>
    <br />
    <p className="custom-space">
      <Link className="btn btn-primary" to="/about-us" title="Find out more">
        Find out more
      </Link>
    </p>
  </div>
)

const Benefits = () => {
  return (
    <Fragment>
      <div className="benefits-area">
        <div className="outer-container">
          <div className="container">
            <ContentDescription />
          </div>
          <img
            alt=""
            src={require("./images/girl-benefits.png").default}
            className="benefits-girl"
          />
        </div>

        <ComponentArt />
      </div>
    </Fragment>
  )
}

const ContentDescription = () => (
  <div className="benefits-description">
    <Title />
    <div className="benefits-point">
      <SubTitle />
      <Description />
    </div>
  </div>
)

const ComponentArt = () => (
  <div className="benefits-art-area">
    <img alt="" src={require("./images/blob.png")} className="mushroom" />
  </div>
)

export default Benefits
